@keyframes blink {
    0% { opacity: 0.2; }
    50% { opacity: 1; }
    100% { opacity: 0.2; }
  }
  
  .blink-image {
    animation: blink 2s linear infinite; /* Changez la durée ou le type d'animation selon vos besoins */
  }

.typing-animation {
  animation: typing 5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}